<template>
    <div class="tw-w-full tw-flex tw-justify-center">
        <q-card
            class="tw-p-4 tw-w-full tw-max-w-full md:tw-max-w-[280px] tw-flex tw-flex-col tw-justify-between tw-border tw-shadow-none"
        >
            <div>
                <q-skeleton height="180px" style="border-radius: 6px" />
                <q-skeleton
                    type="text"
                    width="auto"
                    height="60px"
                    style="border-radius: 6px"
                />
                <q-separator />
                <q-skeleton
                    type="text"
                    width="100px"
                    height="40px"
                    style="border-radius: 6px"
                />
            </div>
            <div
                class="tw-hidden sm:tw-flex sm:tw-justify-between sm:tw-items-center"
            >
                <q-skeleton
                    type="text"
                    width="80px"
                    height="60px"
                    style="border-radius: 6px"
                />
                <q-skeleton
                    type="QBtn"
                    width="80px"
                    style="border-radius: 10px"
                />
            </div>
            <div class="sm:tw-hidden">
                <q-skeleton
                    type="QBtn"
                    width="auto"
                    height="30px"
                    style="border-radius: 6px"
                />
            </div>
        </q-card>
    </div>
</template>
